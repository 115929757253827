import React from 'react'

function InstagramIcon() {
  return (
    <svg
      role='img'
      aria-label='Instagram'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>Instagram</title>
      <rect x='2' y='2' width='20' height='20' rx='5' ry='5'></rect>
      <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z'></path>
      <line x1='17.5' y1='6.5' x2='17.51' y2='6.5'></line>
    </svg>
  )
}

export function SolidInstagramIcon() {
  return (
    <svg
      role='img'
      aria-label='Instagram'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Instagram</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.48871 0H14.4007C17.4274 0 19.8898 2.46235 19.8896 5.48882V14.4008C19.8896 17.4273 17.4274 19.8896 14.4007 19.8896H5.48871C2.46224 19.8896 0 17.4274 0 14.4008V5.48882C0 2.46235 2.46224 0 5.48871 0ZM14.4017 18.1259C16.4553 18.1259 18.126 16.4553 18.126 14.4017H18.1259V5.48974C18.1259 3.43633 16.4552 1.76562 14.4016 1.76562H5.48963C3.43621 1.76562 1.76562 3.43633 1.76562 5.48974V14.4017C1.76562 16.4553 3.43621 18.126 5.48963 18.1259H14.4017ZM4.82031 9.94537C4.82031 7.11937 7.11937 4.82031 9.94537 4.82031C12.7714 4.82031 15.0704 7.11937 15.0704 9.94537C15.0704 12.7713 12.7714 15.0702 9.94537 15.0702C7.11937 15.0702 4.82031 12.7713 4.82031 9.94537ZM6.58594 9.94629C6.58594 11.7991 8.09347 13.3064 9.94629 13.3064C11.7991 13.3064 13.3066 11.7991 13.3066 9.94629C13.3066 8.09335 11.7992 6.58594 9.94629 6.58594C8.09335 6.58594 6.58594 8.09335 6.58594 9.94629ZM14.3702 3.70304C14.6104 3.46187 14.9444 3.32422 15.2844 3.32422C15.6255 3.32422 15.9596 3.46187 16.1996 3.70304C16.4408 3.94304 16.5785 4.27716 16.5785 4.61834C16.5785 4.95834 16.4408 5.29245 16.1996 5.53363C15.9585 5.77363 15.6255 5.91245 15.2844 5.91245C14.9444 5.91245 14.6102 5.77363 14.3702 5.53363C14.1291 5.29245 13.9902 4.95845 13.9902 4.61834C13.9902 4.27716 14.1289 3.94304 14.3702 3.70304Z'
        fill='white'
      />
      <mask
        id='mask0_869_33851'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.48871 0H14.4007C17.4274 0 19.8898 2.46235 19.8896 5.48882V14.4008C19.8896 17.4273 17.4274 19.8896 14.4007 19.8896H5.48871C2.46224 19.8896 0 17.4274 0 14.4008V5.48882C0 2.46235 2.46224 0 5.48871 0ZM14.4017 18.1259C16.4553 18.1259 18.126 16.4553 18.126 14.4017H18.1259V5.48974C18.1259 3.43633 16.4552 1.76562 14.4016 1.76562H5.48963C3.43621 1.76562 1.76562 3.43633 1.76562 5.48974V14.4017C1.76562 16.4553 3.43621 18.126 5.48963 18.1259H14.4017ZM4.82031 9.94537C4.82031 7.11937 7.11937 4.82031 9.94537 4.82031C12.7714 4.82031 15.0704 7.11937 15.0704 9.94537C15.0704 12.7713 12.7714 15.0702 9.94537 15.0702C7.11937 15.0702 4.82031 12.7713 4.82031 9.94537ZM6.58594 9.94629C6.58594 11.7991 8.09347 13.3064 9.94629 13.3064C11.7991 13.3064 13.3066 11.7991 13.3066 9.94629C13.3066 8.09335 11.7992 6.58594 9.94629 6.58594C8.09335 6.58594 6.58594 8.09335 6.58594 9.94629ZM14.3702 3.70304C14.6104 3.46187 14.9444 3.32422 15.2844 3.32422C15.6255 3.32422 15.9596 3.46187 16.1996 3.70304C16.4408 3.94304 16.5785 4.27716 16.5785 4.61834C16.5785 4.95834 16.4408 5.29245 16.1996 5.53363C15.9585 5.77363 15.6255 5.91245 15.2844 5.91245C14.9444 5.91245 14.6102 5.77363 14.3702 5.53363C14.1291 5.29245 13.9902 4.95845 13.9902 4.61834C13.9902 4.27716 14.1289 3.94304 14.3702 3.70304Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_869_33851)'>
        <rect width='20' height='20' fill='none' />
      </g>
    </svg>
  )
}

export default InstagramIcon
