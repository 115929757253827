import classNames from 'classnames'
import { ReactNode } from 'react'

export function FormNotification({
  message,
  className,
  success = false,
  error = false
}: {
  message: ReactNode
  className?: string
  success?: boolean
  error?: boolean
  hideIcon?: boolean
}) {
  return (
    <div
      className={classNames(
        'mb-3 rounded-lg px-3 py-[10px] text-sm border text-center',
        {
          'bg-[#1A652A] border-[#1A652A] text-[#AFF5B4]': success,
          'bg-[#FFD5D5] border-[#C61010] text-[#C61010]': error
        },
        className
      )}
    >
      {message}
    </div>
  )
}
