import React from 'react'
import classNames from 'classnames'
import { SolidFacebookIcon } from '@src/icons/facebook'
import { SolidInstagramIcon } from '@src/icons/instagram'
import { SolidTwitterIcon } from '@src/icons/twitter'
import { SolidYoutubeIcon } from '@src/icons/youtube'
import AllyantLogo from '@src/icons/allyant-logo'

const Footer = ({
  image,
  minimal = true,
  className
}: {
  image: string
  minimal?: boolean
  className?: string
}) => {
  return (
    <footer
      role='contentinfo'
      className={classNames(
        'w-full pt-10 md:px-10 pb-5 text-left border-t border-[#7b7b7b] md:border-0',
        {
          [`bg-[url('/static/images/hero_footer.jpg')] bg-[length:auto_100%] md:bg-cover bg-no-repeat bg-center h-64 text-white`]:
            !minimal,
          'bg-black  text-gray-300': minimal
        },
        className
      )}
    >
      <div className='flex flex-col w-full md:flex-row'>
        <div className='flex flex-col justify-start w-full px-4 pb-10 md:px-0 md:flex-row-reverse md:justify-between align-center'>
          <ul className='flex flex-row items-center justify-start mb-10 md:mx-auto md:mt-0 md:mb-0 gap-x-5'>
            <li>
              <a
                href='https://www.facebook.com/barstoolsports'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SolidFacebookIcon />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/barstoolsports'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SolidTwitterIcon />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/barstoolsports/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SolidInstagramIcon />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/c/barstoolsportstv/videos'
                target='_blank'
                rel='noopener noreferrer'
              >
                <SolidYoutubeIcon />
              </a>
            </li>
          </ul>

          <div className='flex flex-col justify-start'>
            <nav aria-label='footer menu'>
              <ul className='flex flex-row mb-6 space-x-4 font-bold'>
                <li>
                  <a href='http://help.barstoolsports.com' target='_blank' rel='noopener'>
                    Help
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.barstoolsports.com/terms-of-use'
                    target='_blank'
                    rel='noopener'
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.barstoolsports.com/privacy-policy'
                    target='_blank'
                    rel='noopener'
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>
            <div className='text-sm'>
              &copy; {new Date().getFullYear()} Barstool Sports - All rights reserved.
              <br />
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-start px-4 md:px-0 pt-5 md:pt-0 border-t border-[#7b7b7b] md:border-0'>
          <a href='http://www.allyant.com' target='_blank' rel='noopener noreferrer'>
            <AllyantLogo />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
