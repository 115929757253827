import React from 'react'

function TwitterIcon() {
  return (
    <svg
      role='img'
      aria-label='Twitter'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>Twitter</title>
      <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z'></path>
    </svg>
  )
}

export function SolidTwitterIcon() {
  return (
    <svg
      role='img'
      aria-label='Twitter'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Twitter</title>
      <path
        d='M20 3.58781C19.2563 3.91406 18.4638 4.13031 17.6375 4.23531C18.4875 3.72781 19.1362 2.93031 19.4412 1.96906C18.6488 2.44156 17.7737 2.77531 16.8412 2.96156C16.0887 2.16031 15.0162 1.66406 13.8462 1.66406C11.5762 1.66406 9.74875 3.50656 9.74875 5.76531C9.74875 6.09031 9.77625 6.40281 9.84375 6.70031C6.435 6.53406 3.41875 4.90031 1.3925 2.41156C1.03875 3.02531 0.83125 3.72781 0.83125 4.48406C0.83125 5.90406 1.5625 7.16281 2.6525 7.89156C1.99375 7.87906 1.3475 7.68781 0.8 7.38656C0.8 7.39906 0.8 7.41531 0.8 7.43156C0.8 9.42406 2.22125 11.0791 4.085 11.4603C3.75125 11.5516 3.3875 11.5953 3.01 11.5953C2.7475 11.5953 2.4825 11.5803 2.23375 11.5253C2.765 13.1491 4.2725 14.3428 6.065 14.3816C4.67 15.4728 2.89875 16.1303 0.98125 16.1303C0.645 16.1303 0.3225 16.1153 0 16.0741C1.81625 17.2453 3.96875 17.9141 6.29 17.9141C13.835 17.9141 17.96 11.6641 17.96 6.24656C17.96 6.06531 17.9538 5.89031 17.945 5.71656C18.7587 5.13906 19.4425 4.41781 20 3.58781Z'
        fill='white'
      />
      <mask
        id='mask0_869_33846'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='1'
        width='20'
        height='17'
      >
        <path
          d='M20 3.58781C19.2563 3.91406 18.4638 4.13031 17.6375 4.23531C18.4875 3.72781 19.1362 2.93031 19.4412 1.96906C18.6488 2.44156 17.7737 2.77531 16.8412 2.96156C16.0887 2.16031 15.0162 1.66406 13.8462 1.66406C11.5762 1.66406 9.74875 3.50656 9.74875 5.76531C9.74875 6.09031 9.77625 6.40281 9.84375 6.70031C6.435 6.53406 3.41875 4.90031 1.3925 2.41156C1.03875 3.02531 0.83125 3.72781 0.83125 4.48406C0.83125 5.90406 1.5625 7.16281 2.6525 7.89156C1.99375 7.87906 1.3475 7.68781 0.8 7.38656C0.8 7.39906 0.8 7.41531 0.8 7.43156C0.8 9.42406 2.22125 11.0791 4.085 11.4603C3.75125 11.5516 3.3875 11.5953 3.01 11.5953C2.7475 11.5953 2.4825 11.5803 2.23375 11.5253C2.765 13.1491 4.2725 14.3428 6.065 14.3816C4.67 15.4728 2.89875 16.1303 0.98125 16.1303C0.645 16.1303 0.3225 16.1153 0 16.0741C1.81625 17.2453 3.96875 17.9141 6.29 17.9141C13.835 17.9141 17.96 11.6641 17.96 6.24656C17.96 6.06531 17.9538 5.89031 17.945 5.71656C18.7587 5.13906 19.4425 4.41781 20 3.58781Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_869_33846)'>
        <rect width='20' height='20' fill='none' />
      </g>
    </svg>
  )
}

export default TwitterIcon
