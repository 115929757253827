import React from 'react'
import NextHead from 'next/head'
import Script from 'next/script'

const Head = ({
  noIndexNoFollow = false,
  noAutoZoom = false,
  story = null,
  videoSource = null,
  viralPost = null,
  playlist = null,
  canonical = null,
  twitterHandler = null,
  children = null,
  ogImage = '/static/images/barstooltv_share.png',
  ...props
}) => {
  return (
    <NextHead>
      <meta charSet='UTF-8' />
      <meta
        name='viewport'
        content={`width=device-width, initial-scale=1, maximum-scale=1`}
        // content={`width=device-width, initial-scale=1${ noAutoZoom ? ', maximum-scale=1' : ''}`} // @TODO: Double check it's OK to just always prevent auto zoom
      />
      <link rel='icon' href='/favicon.ico' />

      {/* Page specific head content */}
      <title>{props.title || 'Barstool Sports'}</title>
      <meta name='description' content={props.description} />

      {/* <meta name='theme-color' content='rgb(79, 70, 237)' /> */}
      <meta property='og:title' content={props.title || ''} />
      <meta property='og:description' content={props.description} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:image:width' content={props.ogImageWidth || 1200} />
      <meta property='og:image:height' content={props.ogImageHeight || 630} />
      <meta property='og:type' content={props.ogType || 'website'} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={props.title || ''} />
      <meta name='twitter:description' content={props.description} />
      <meta name='twitter:site' content={twitterHandler || '@barstoolsports'} />
      <meta name='twitter:image' content={ogImage} />

      {/* Disable crawlers */}
      {(noIndexNoFollow || process.env.SEO_CRAWLING_DISABLED === 'true') && (
        <meta name='robots' content='noindex, nofollow' />
      )}

      {/* Global site tag (gtag.js) - Google Analytics --> */}
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-9PWZ36STZH'
        strategy='afterInteractive'
      />

      <Script id='google-analytics' strategy='afterInteractive'>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-9PWZ36STZH');
        `}
      </Script>

      {/* Render children */}
      {children}
    </NextHead>
  )
}

export default Head
