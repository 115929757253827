import { Service, SWRConfiguration, useGet, useList, useRead } from '@api/common'
import { apiHost } from '@src/lib/env'
import type { Event, EventVideoSource, ListParams, ReadParams, WatchHistory } from './types'
import useSWR from 'swr'

export type { Event, EventVideoSource }

export class EventService extends Service<Event> {
  constructor() {
    super('/events', apiHost)
  }

  async getEvent(id: string | undefined): Promise<any> {
    try {
      return id ? await this.get(`/events/${id}`) : null
    } catch (e) {
      return null
    }
  }

  async getLiveEvent(): Promise<any> {
    return await this.get(`/events/live`, { origin: true, type: 'barstool_tv' })
  }

  async videoSource(id: string, options?: { origin: boolean }): Promise<EventVideoSource> {
    return await this.get(`/events/${id}/video-source`, options)
  }

  async getWatchHistory(id: string): Promise<WatchHistory> {
    return await this.get(`/events/${id}/watch-history`)
  }

  async liveVideoSource(): Promise<EventVideoSource> {
    return await this.get(`/events/live-video-source`, { origin: true, type: 'barstool_tv' })
  }
}

export const eventService = new EventService()

export function useEventVideoSource(id: string | undefined, config?: SWRConfiguration) {
  return useGet<EventVideoSource>(
    eventService,
    id ? `/events/${id}/video-source` : null,
    { origin: true },
    {
      ...config,
      isPaused: () => typeof id !== 'string'
    }
  )
}

export function useLiveEventVideoSource(config?: SWRConfiguration) {
  const cacheKey = JSON.stringify({ origin: true, type: 'barstool_tv' })
  const path = '/events/live-video-source'
  const fetcher = async () => {
    try {
      return await eventService.liveVideoSource()
    } catch (error) {
      console.error(error)
      return await eventService.videoSource(process.env.NEXT_PUBLIC_LIVE_EVENT as string)
    }
  }
  return useSWR([path, cacheKey], fetcher, config)
}

export function useEvents(params?: ListParams<Event>, config?: SWRConfiguration) {
  return useList(eventService, params, config)
}

export function useEvent(id: string | undefined, params?: ReadParams, config?: SWRConfiguration) {
  return useRead(eventService, id, params, config)
}

export function useLiveEvent(config?: SWRConfiguration) {
  const cacheKey = JSON.stringify({ origin: true, type: 'barstool_tv' })
  const path = '/events/live'
  const fetcher = async () => {
    try {
      return await eventService.getLiveEvent()
    } catch (error) {
      console.error(error)
      return await eventService.getEvent(process.env.NEXT_PUBLIC_LIVE_EVENT as string)
    }
  }
  return useSWR([path, cacheKey], fetcher, config)
}

export function useWatchHistory(id: string | undefined) {
  return useGet<WatchHistory>(eventService, id ? `/events/${id}/watch-history` : null)
}
