import React from 'react'

function YoutubeIcon() {
  return (
    <svg
      role='img'
      aria-label='Youtube'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>Youtube</title>
      <path d='M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z'></path>
      <polygon points='9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02'></polygon>
    </svg>
  )
}

export function SolidYoutubeIcon() {
  return (
    <svg
      role='img'
      aria-label='Youtube'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Youtube</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.82 3.45094C18.0187 3.51844 18.6075 3.69594 19.15 4.66094C19.715 5.62469 20 7.28469 20 10.2084V10.2122V10.2184C20 13.1284 19.715 14.8009 19.1513 15.7547C18.6087 16.7197 18.02 16.8947 16.8212 16.9759C15.6225 17.0447 12.6112 17.0859 10.0025 17.0859C7.38875 17.0859 4.37625 17.0447 3.17875 16.9747C1.9825 16.8934 1.39375 16.7184 0.84625 15.7534C0.2875 14.7997 0 13.1272 0 10.2172V10.2147V10.2109V10.2072C0 7.28469 0.2875 5.62469 0.84625 4.66094C1.39375 3.69469 1.98375 3.51844 3.18 3.44969C4.37625 3.36969 7.38875 3.33594 10.0025 3.33594C12.6112 3.33594 15.6225 3.36969 16.82 3.45094ZM13.75 10.2109L7.5 6.46094V13.9609L13.75 10.2109Z'
        fill='white'
      />
      <mask
        id='mask0_869_33870'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='3'
        width='20'
        height='15'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.82 3.45094C18.0187 3.51844 18.6075 3.69594 19.15 4.66094C19.715 5.62469 20 7.28469 20 10.2084V10.2122V10.2184C20 13.1284 19.715 14.8009 19.1513 15.7547C18.6087 16.7197 18.02 16.8947 16.8212 16.9759C15.6225 17.0447 12.6112 17.0859 10.0025 17.0859C7.38875 17.0859 4.37625 17.0447 3.17875 16.9747C1.9825 16.8934 1.39375 16.7184 0.84625 15.7534C0.2875 14.7997 0 13.1272 0 10.2172V10.2147V10.2109V10.2072C0 7.28469 0.2875 5.62469 0.84625 4.66094C1.39375 3.69469 1.98375 3.51844 3.18 3.44969C4.37625 3.36969 7.38875 3.33594 10.0025 3.33594C12.6112 3.33594 15.6225 3.36969 16.82 3.45094ZM13.75 10.2109L7.5 6.46094V13.9609L13.75 10.2109Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_869_33870)'>
        <rect width='20' height='20' fill='none' />
      </g>
    </svg>
  )
}
export default YoutubeIcon
