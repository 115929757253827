import { isMobile } from '@barstoolsports/web-sdk'
import { Dialog } from '@headlessui/react'
import CloseIcon from '@src/icons/close'
import classNames from 'classnames'
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useRef } from 'react'

export interface ModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  title?: ReactNode
  subtitle?: ReactNode
  children: ReactNode
  isFullBleed?: boolean
  classname?: string
  hideCloseIcon?: boolean
  onClose?: () => void
  modalType?: 'detail' | 'payment' | 'login' | 'share' | ''
}

/**
 * This component is used to render a modal. It is used in the following places:
 * - src/components/detail-modal.tsx
 * - src/components/share-modal.tsx
 * - src/components/payment-method-modal.tsx
 * - src/components/login-modal.tsx
 * @param isOpen - boolean that determines whether the modal is open or not
 * @param setIsOpen - function that sets the isOpen state
 * @param title - optional ReactNode that renders a title in the modal
 * @param subtitle - optional ReactNode that renders a subtitle in the modal
 * @param children - ReactNode that renders the content of the modal
 * @param isFullBleed - boolean that determines whether the modal is full bleed or not
 * @param classname - optional string that adds additional classes to the modal (dialog only)
 * @param hideCloseIcon - boolean that determines whether the close icon is hidden or not
 * @param onClose - optional function that is called when the modal is closed
 * @param modalType - optional string that determines the type of modal being rendered
 */

export function Modal({
  isOpen,
  setIsOpen,
  title,
  subtitle,
  hideCloseIcon,
  onClose,
  children,
  isFullBleed = false,
  classname = '',
  modalType = ''
}: ModalProps) {
  const scrollY = useRef<number>()
  const mobileModal = useMemo(() => isMobile(), [])
  function handleClose() {
    if (onClose) onClose()
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen && mobileModal) {
      // uses a timeout so that the background scrolling up isn't seen until after the mobile modal drawer animation finishes sliding up
      setTimeout(() => {
        scrollY.current = window.scrollY
        document.body.classList.add('fixed', 'inset-0', 'overflow-hidden')
      }, 400)
      // restores the scroll position when closing the modal or growing wider than mobile
      return () => {
        document.body.classList.remove('fixed', 'inset-0', 'overflow-hidden')
        if (scrollY.current) {
          window.scrollTo(0, scrollY.current)
        }
        scrollY.current = undefined
      }
    }
  }, [isOpen, mobileModal])

  const baseStyles =
    'relative max-h-full w-full bg-[#282828] rounded-t-lg md:rounded-lg border-t md:border border-[#404040] font-sans text-white overflow-y-auto'

  const fullBleedStyles = 'h-full md:max-h-[calc(100vh_-_48px)] md:h-auto md:w-[800px]'

  const defaultStyles =
    'h-[calc(100%-32px)] md:max-h-[calc(100vh_-_48px)] md:h-auto md:w-[400px] p-5 md:p-8 md:pb-16'

  const dialogStyles = `fixed h-[100dvh] inset-0 ${
    modalType === 'detail' ? 'z-[49]' : 'z-[50]'
  } flex items-end justify-center bg-black/60 md:items-center overflow-hidden`

  const className = classNames(baseStyles, isFullBleed ? fullBleedStyles : defaultStyles, classname)

  return (
    <Dialog open={isOpen} onClose={handleClose} className={dialogStyles}>
      <Dialog.Panel id='modal-body' className={className} aria-labelledby='modal-title'>
        {!hideCloseIcon && (
          <button
            type='button'
            aria-label='close the modal'
            onClick={handleClose}
            className='absolute cursor-pointer top-6 right-6 z-[51] bg-black opacity-60 rounded-full p-2 hover:opacity-100'
          >
            <CloseIcon width='13px' height='13px' color='#fff' />
          </button>
        )}
        {title && (
          <Dialog.Title
            className='mb-10 font-sans font-extralight leading-10 mt-[42px] text-[32px]'
            id='modal-title'
          >
            {title}
          </Dialog.Title>
        )}
        {subtitle && <Dialog.Description className='mb-10 text-sm'>{subtitle}</Dialog.Description>}
        {children}
      </Dialog.Panel>
    </Dialog>
  )
}
