import classNames from 'classnames'
import { Field } from 'react-final-form'

interface TextFieldProps extends React.ComponentProps<'input'> {
  name?: string
  label?: string
  error?: string
}

export default function TextField({
  name = '',
  className,
  label,
  placeholder,
  error,
  ...props
}: TextFieldProps) {
  return (
    <Field
      name={name}
      {...props}
      render={({ input }) => {
        return (
          <div
            className={classNames(
              'w-full text-white flex flex-col border-2 border-white rounded-lg bg-transparent py-[10px] px-[15px]',
              { 'opacity-50 cursor-not-allowed': props.disabled },
              className
            )}
          >
            {label && (
              <label className='my-[5px] px-[10px] text-xs font-normal leading-none'>{label}</label>
            )}
            <input
              {...props}
              {...input}
              placeholder={placeholder}
              className={
                'bg-transparent py-[5px] px-[10px] placeholder:text-[#7B7B7B] text-sm border-none leading-none focus:ring-transparent focus:outline-none focus:border-white'
              }
            />
          </div>
        )
      }}
    />
  )
}
