import React from 'react'

function FacebookIcon() {
  return (
    <svg
      role='img'
      aria-label='Facebook'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>Facebook</title>
      <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z'></path>
    </svg>
  )
}

export function SolidFacebookIcon() {
  return (
    <svg
      role='img'
      aria-label='Facebook'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Facebook</title>
      <path
        d='M11.5632 7.68359V5.49609C11.5632 4.89234 12.0765 4.40234 12.709 4.40234H13.8548V1.66797H11.5632C9.66451 1.66797 8.12565 3.13687 8.12565 4.94922V7.68359H5.83398V10.418H8.12565V19.168H11.5632V10.418H13.8548L15.0007 7.68359H11.5632Z'
        fill='white'
      />
      <mask
        id='mask0_869_33841'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='5'
        y='1'
        width='11'
        height='19'
      >
        <path
          d='M11.5632 7.68359V5.49609C11.5632 4.89234 12.0765 4.40234 12.709 4.40234H13.8548V1.66797H11.5632C9.66451 1.66797 8.12565 3.13687 8.12565 4.94922V7.68359H5.83398V10.418H8.12565V19.168H11.5632V10.418H13.8548L15.0007 7.68359H11.5632Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_869_33841)'>
        <rect width='20' height='20' fill='none' />
      </g>
    </svg>
  )
}

export default FacebookIcon
