import Loading from '@src/icons/loading'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

interface ButtonProps {
  onClick?: ((e?: unknown) => void) | undefined
  type?: 'submit' | 'button'
  className?: string
  children: ReactNode
  primary?: boolean
  disabled?: boolean
  loading?: boolean
}

export default function Button({
  className,
  loading,
  children,
  disabled,
  primary,
  ...props
}: ButtonProps) {
  return (
    <button
      className={classNames(
        'relative will-change-colors transition-colors rounded-lg w-full py-[10px] px-3 font-bold font-sans tracking-wide',
        {
          'bg-[#C61010] text-white hover:bg-[#A70606]': primary,
          'opacity-50 cursor-not-allowed': disabled || loading
        },
        className
      )}
      disabled={loading || disabled}
      {...props}
    >
      <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</span>
      {loading && (
        <div
          className='absolute top-0 bottom-0 left-0 items-center justify-center w-full h-full'
          style={{
            display: loading ? 'flex' : 'none'
          }}
        >
          <Loading height='24px' />
        </div>
      )}
    </button>
  )
}
