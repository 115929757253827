import { unionApiHost } from '@src/lib/env'
import useSWR, { SWRConfiguration } from 'swr'
import { Service, useList, useRead } from './common'
import { ListParams } from './types'

class PlaylistApi extends Service<any> {
  constructor() {
    super('/playlists', unionApiHost)
  }

  async findPlaylistsById(ids: string[], params?: ListParams<any>) {
    const findPlaylist = async (id: string) => {
      const result = await this.read(id, params)

      return result
    }
    return await Promise.all(ids.map(findPlaylist))
  }
}

export const playlistApi = new PlaylistApi()

export function usePlaylists(params: ListParams<any>, config: SWRConfiguration) {
  return useList(playlistApi, params, config)
}

export function usePlaylist(id: string, params?: ListParams<any>, config?: SWRConfiguration) {
  return useRead(playlistApi, id, params, config)
}

export function usePlaylistsById(
  ids: string[],
  params?: ListParams<any>,
  config?: SWRConfiguration
) {
  return useSWR(
    ids ? [playlistApi.basePath, ids, params] : null,
    () => playlistApi.findPlaylistsById(ids, params),
    config
  )
}
